import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RxCross2 } from "react-icons/rx";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import AppContext from "../../context/AppContext";

export const ColumnMap = ({
  fetchData,
  uploadProgress,
  setUploadProgress,
  mapopen,
  setMapOpen,
  colHeaders,
  optionalFields,
  mandatoryFields,
  setModalValue,
  open,
  setOpen,
  newFile,
  selectedOption,
  showprogress, setShowProgress
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isNewStructure, setIsNewStructure] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const xhrRef = useRef(null);
  const [taskPriority, setTaskPriority] = useState("Low_Priority");
  const [matchedColumns, setmatchedColumns] = useState({});
  const { theme } = useContext(AppContext);
  useEffect(() => {
    if (open && xhrRef.current) {
      xhrRef.current.abort();
      toast.error("File upload aborted due to an error.");
      setUploadProgress(0);
    }
  }, [open]);

  const uploadFile = async (
    file,
    selectedOption,
    matchedColumns,
    taskPriority
  ) => {
    const formData = new FormData();
    formData.append("excel", file);
    formData.append("workflowId", selectedOption); 
    formData.append("columnMappings", JSON.stringify(matchedColumns));
    formData.append("taskPriority", taskPriority); 
    const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/task/excel-upload`;
    try {
      const xhr = new XMLHttpRequest();
      xhrRef.current = xhr;
      xhr.open("POST", url);
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      }
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          setUploadProgress(percentComplete);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const responseData = JSON.parse(xhr.responseText);
          if (responseData.header.code === 600) {
            setUploadProgress(100);
            toast.success(responseData.body.value);
            setShowProgress(false)
          } else {
            setOpen(true);
            setModalValue(responseData.body.value);
            setShowProgress(false)
          }
        } else {
          console.error("File upload failed", xhr.status);
          setShowProgress(false)
        }
      };
      xhr.onerror = () => {};
      xhr.send(formData);
    } catch (error) {}
  };

  const handleNext = () => {
    if (currentStep === 1) {
      if (isNewStructure === null) {
        alert("Please select an option.");
      } else if (isNewStructure) {
        setShowConfirmation(true);
      } else {
        setCurrentStep(2);
      }
    } else {
      setCurrentStep(3);
    }
  };

  const handleBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      setCurrentStep(2);
    }
  };

  const handleSubmit = () => {
    uploadFile(newFile, selectedOption, matchedColumns, taskPriority);
    setMapOpen(false);
  };
  const handleSelectChange = (fieldLabel, value) => {
    setmatchedColumns((prev) => ({
      ...prev,
      [fieldLabel]: value,
    }));
  };

  const getFilteredOptions = (fieldLabel) => {
    const currentValue = matchedColumns[fieldLabel];
    return colHeaders.filter(
      (option) =>
        !Object.values(matchedColumns).includes(option) ||
        option === currentValue
    );
  };
  const handleClose =()=>{
    setMapOpen(false)
    setShowProgress(false)
  }
  return (
    <Transition.Root show={mapopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setMapOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-70 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 flex items-center justify-center p-4 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={`relative transform overflow-hidden rounded-lg bg-white text-left
           shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl ${
             theme === "dark"
               ? "dark-mode "
               : theme === "high-contrast"
               ? "high-contrast "
               : ""   }`} >
              <div className="relative p-6">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors"
                  onClick={handleClose}
                >
                  <RxCross2 className="w-6 h-6" />
                </button>
                <ol className="flex mt-5 items-center w-full text-sm font-medium text-gray-600 sm:text-base mb-6">
                  <li
                    className={`flex items-center ${
                      currentStep === 1 ? "text-blue-600" : "text-gray-500"
                    } after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:hidden sm:after:inline-block sm:after:mx-6 xl:after:mx-10`}
                  >
                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 text-sm">
                      <FaCircleCheck className="text-blue-500 mr-2" />
                      Task{" "}
                      <span className="hidden sm:inline-flex sm:ml-2 text-sm">
                        Priority
                      </span>
                    </span>
                  </li>
                  <li
                    className={`flex items-center ${
                      currentStep === 2 ? "text-blue-600" : "text-gray-500"
                    } after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:hidden sm:after:inline-block sm:after:mx-6 xl:after:mx-10`}
                  >
                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 text-sm">
                      <FaCircleCheck className="text-blue-500 mr-2" />
                      Mandatory{" "}
                      <span className="hidden sm:inline-flex sm:ml-2 text-sm">
                        Fields
                      </span>
                    </span>
                  </li>
                  <li
                    className={`flex items-center ${
                      currentStep === 3 ? "text-blue-600" : "text-gray-500"
                    } after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:hidden sm:after:inline-block sm:after:mx-6 xl:after:mx-10`}
                  >
                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 text-sm">
                      <FaCircleCheck className="text-blue-500 mr-2" />
                      Other{" "}
                      <span className="hidden sm:inline-flex sm:ml-2 text-sm">
                        Fields
                      </span>
                    </span>
                  </li>
                </ol>
                {currentStep === 1 && (
                  <div className="flex flex-col gap-8">
                    <div>
                      <div className="mt-4">
                        <div>
                          <input
                            type="radio"
                            name="taskPriority"
                            value="Low_Priority"
                            checked={taskPriority === "Low_Priority"}
                            onChange={() => setTaskPriority("Low_Priority")}
                          />
                          <label className="ml-2 text-sm">Low Priority</label>
                        </div>
                        <div className="mt-2">
                          <input
                            type="radio"
                            name="taskPriority"
                            value="Medium_Priority"
                            checked={taskPriority === "Medium_Priority"}
                            onChange={() => setTaskPriority("Medium_Priority")}
                          />
                          <label className="ml-2 text-sm">Medium Priority</label>
                        </div>
                        <div className="mt-2">
                          <input
                            type="radio"
                            name="taskPriority"
                            value="High_Priority"
                            checked={taskPriority === "High_Priority"}
                            onChange={() => setTaskPriority("High_Priority")}
                          />
                          <label className="ml-2 text-sm">High Priority</label>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleNext}
                        className="px-4 py-2 bg-blue-700 text-white rounded-md transition-colors hover:bg-blue-800"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div>
                    <h3 className="text-red-600 mb-4">Mandatory Fields *</h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {mandatoryFields.map((label, index) => (
                        <div className="mb-4" key={index}>
                          <label
                            className={`block text-gray-700 mb-2 text-sm ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast "
                                : ""
                            }`}
                          >
                            {label}
                          </label>
                          <select
                            className={`w-full text-sm px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                             focus:ring-2 focus:ring-blue-500 ${
                               theme === "dark"
                                 ? "dark-mode "
                                 : theme === "high-contrast"
                                 ? "high-contrast "
                                 : ""
                             }`}
                            value={matchedColumns[label] || ""}
                            onChange={(e) =>
                              handleSelectChange(label, e.target.value)
                            }
                          >
                            <option value="">Select a value</option>
                            {getFilteredOptions(label).map((option) => (
                              <option
                                key={option}
                                value={option}
                                className={` text-sm ${
                                  theme === "dark"
                                    ? "dark-mode "
                                    : theme === "high-contrast"
                                    ? "high-contrast "
                                    : ""
                                }`}
                              >
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        onClick={handleBack}
                        className="text-sm px-4 py-2 bg-blue-700 text-white rounded-md mr-2 transition-colors hover:bg-blue-800"
                      >
                        Back
                      </button>
                      {isNewStructure || optionalFields.length === 0 ? (
                        <button
                          onClick={handleSubmit}
                          className="px-4 text-sm py-2 bg-green-600 text-white rounded-md transition-colors hover:bg-green-700"
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          onClick={handleNext}
                          className="px-4 text-sm py-2 bg-blue-700 text-white rounded-md transition-colors hover:bg-blue-800"
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {currentStep === 3 && optionalFields.length > 0 && (
                  <div className="overflow-y-auto max-h-[400px]">
                    <h3 className="text-red-600 mb-4 text-sm">Other Fields</h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 ">
                      {optionalFields.map((label, index) => (
                        <div className="mb-4" key={index}>
                          <label
                            className={`block text-sm text-gray-700 mb-2 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast "
                                : ""
                            }`}
                          >
                            {label}
                          </label>
                          <select
                            className={`w-full text-sm px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                            focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast "
                                : ""
                            }`}
                            value={matchedColumns[label] || ""}
                            onChange={(e) =>
                              handleSelectChange(label, e.target.value)
                            }
                          >
                            <option value="">Select a value</option>
                            {getFilteredOptions(label).map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        onClick={handleBack}
                        className="px-4 py-2 bg-blue-700 text-white rounded-md mr-2 transition-colors hover:bg-blue-800"
                      >
                        Back
                      </button>
                      <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-green-600 text-white rounded-md transition-colors hover:bg-green-700"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
