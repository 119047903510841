import React, { useContext, useState, useEffect, useCallback } from "react";
import AppContext from "../../context/AppContext";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { fetchInventory } from "../../service/Inventory";
import { useNavigate } from "react-router-dom";
import { FiDownloadCloud, FiUploadCloud } from "react-icons/fi";
import { Spinner } from "../../Spinner";
import { UploadExcelReport } from "./UploadExcelReport";
import { DownloadReport } from "./DownloadReport";
import { IoDownloadOutline } from "react-icons/io5";
import importImg from "../../assets/arrow-undo-circle.png";

const translations = {
  en: {
    stockmanag: "Stock Management",
    fromdate: "From Date",
    todate: "To Date",
    metercode: "Meter Bar Code",
    location: "To Location",
    filter: "Apply Filters",
    download: "Download",
    uploadSheet: "Upload  Sheet",
    edit: "Edit",
  },
  ar: {
    stockmanag: "إدارة المخزون",
    fromdate: "من التاريخ",
    todate: "حتى الآن",
    metercode: "رمز شريط العداد",
    location: "إلى الموقع",
    filter: "تطبيق المرشحات",
    download: "تحميل",
    uploadSheet: "تحميل الورقة",
    edit: "يحرر",
  },
};
export const StockManagement = () => {
  const { theme, selectedOrgId, langMode } = useContext(AppContext);
  const navigate = useNavigate();
  const [inventoryDetails, setInventoryDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState();
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [open, setOpen] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Adjust options for custom formatting if needed
  };
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    meterBarCode: "",
    toLocation: "",
  });

  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const fetchInventoryData = useCallback(async () => {
    try {
      setLoading(true);
      const { fromDate, toDate, meterBarCode, toLocation } = filters;
      const data = await fetchInventory(
        currentPage,
        pageSize,
        fromDate,
        toDate,
        meterBarCode,
        toLocation,
        selectedOrgId
      );
      setInventoryDetails(data.meterInventoryList || []);
      setBarcode(data.meterInventoryList.meterBarCode);
      setTotalValue(data?.totalSize || 0);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch inventory data", error);
      setLoading(false);
    }
  }, [currentPage, pageSize, filters, selectedOrgId]);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const handleApplyFilters = () => {
    setCurrentPage(0);
    fetchInventoryData();
  };
  useEffect(() => {
    if (selectedOrgId) {
      setCurrentPage(0);
      setPageSize(10);
      fetchInventoryData(selectedOrgId, currentPage, pageSize);
    }
  }, [selectedOrgId]);

  const renderDynamicHeaders = () => {
    if (!inventoryDetails.length) return null;
    return Object.keys(inventoryDetails[0])
      .filter((key) => key !== "id" && key !== "organizationId")
      .map((key) => (
        <th key={key} className="px-6 py-3 font-normal text-xs text-nowrap">
          {key.replace(/([A-Z])/g, " $1").toUpperCase()}
        </th>
      ));
  };

  const renderDynamicRows = (item) => {
    return Object.keys(item)
      .filter((key) => key !== "id" && key !== "organizationId")
      .map((key) => {
        let value = item[key];
        if (key === "createdAt" || key === "activationDate") {
          value = value ? formatDate(value) : "N/A";
        } else {
          value = value !== null && value !== undefined ? value : "N/A";
        }
        return (
          <td key={key} className="px-6 py-4 text-nowrap text-xs">
            {value}
          </td>
        );
      });
  };

  const handleEditClick = (item) => {
    const barcode = item;
    console.log(barcode, "barcode");
    const path = `/update-inventory/${barcode}`;
    navigate(path);
  };

  const UploadReport = () => {
    setOpen(true);
  };
  const DownloadModal = () => {
    setOpenModal(true);
  };
  return (
    <div>
      <h4
        className={`text-md mt-5 font-semibold text-gray-800 ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {translations[langMode].stockmanag}
      </h4>

      <div
        className={`my-4 flex flex-wrap gap-4  p-2 ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {[
          {
            label: translations[langMode].fromdate,
            name: "fromDate",
            type: "datetime-local",
          },
          {
            label: translations[langMode].todate,
            name: "toDate",
            type: "datetime-local",
          },
          {
            label: translations[langMode].metercode,
            name: "meterBarCode",
            type: "text",
          },
          {
            label: translations[langMode].location,
            name: "toLocation",
            type: "text",
          },
        ].map((input) => (
          <div key={input.name}>
            <label
              htmlFor={input.name}
              className={`block text-sm font-medium text-gray-700 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              {input.label}
            </label>
            <input
              type={input.type}
              id={input.name}
              name={input.name}
              className={`mt-1 block w-full p-2 border border-gray-300 rounded-md h-9 text-sm ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              value={filters[input.name]}
              onChange={handleInputChange}
            />
          </div>
        ))}

        <button
          onClick={handleApplyFilters}
          className={`mt-auto bg-blue-800 hover:bg-blue-700 text-white px-2 py-2 text-sm rounded-md ${
            theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
          }`}
        >
          {translations[langMode].filter}
        </button>

        <button
          className={`mt-auto bg-yellow-400 hover:bg-yellow-500 inline-flex gap-3 text-white px-2 py-2 text-sm rounded-md ${
            theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
          }`}
          onClick={DownloadModal}
        >
          <IoDownloadOutline size={20} /> {translations[langMode].download}
        </button>
        <button
          onClick={UploadReport}
          className={`mt-auto bg-blue-800 hover:bg-blue-700 inline-flex gap-3 text-white px-2 py-2 text-sm rounded-md ${
            theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
          }`}
        >
          {/* <FiUploadCloud size={20} /> {translations[langMode].upload} */}
          <img src={importImg} className="px-1 h-5" alt="" />
          {translations[langMode].uploadSheet} 
        </button>
      </div>
      <div
        className={` my-1 bg-white shadow-md sm:rounded-lg mr-14 ${
          theme === "dark"
            ? "dark-mode border "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {totalValue !== 0 && (
          <div className="px-5 py-1 flex justify-between items-center">
            <div className="text-sm text-gray-400 flex items-center gap-4">
              <label htmlFor="pageSize" className=" text-xs ">
                Rows per page:
              </label>
              <select
                id="pageSize"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {[5, 10, 20].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-sm text-gray-400 flex items-center gap-4">
              {`${startIndex + 1}-${Math.min(
                endIndex,
                totalValue
              )} of ${totalValue} items`}
              <IoIosArrowBack
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                disabled={currentPage === 0}
              />
              <IoIosArrowForward
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              />
            </div>
          </div>
        )}
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead
              className={`text-xs text-gray-700 uppercase bg-gray-100 ${
                theme === "dark"
                  ? "bg-gray-800 text-white"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <tr className="border-b">
                {renderDynamicHeaders()}
                {totalValue !== 0 && (
                  <th className="px-6 py-3">
                    <span className=" text-xs ">Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {loading && <Spinner />}
              {inventoryDetails.map((item, index) => (
                <tr
                  key={index}
                  className={` ${
                    theme === "dark"
                      ? "dark-mode hover:bg-gray-800 "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }bg-white border-b `}
                >
                  {renderDynamicRows(item)}
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleEditClick(item.meterBarCode)}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-center  text-xs "
                    >
                      {translations[langMode].edit}
                    </button>
                  </td>
                </tr>
              ))}
              {totalValue === 0 && (
                <p className="text-center py-3">No Data available</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <UploadExcelReport
        open={open}
        setOpen={setOpen}
        fetchInventoryData={fetchInventoryData}
      />
      <DownloadReport openmodal={openmodal} setOpenModal={setOpenModal} />
    </div>
  );
};
