import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchCompletedTask } from "../../../service/Task";

export default function UpdateTask() {
  const { taskId } = useParams();
  const [taskDetail, setTaskDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getTaskData = async () => {
      try {
        const taskData = await fetchCompletedTask(taskId);
        setTaskDetail(taskData.body?.value?.fieldList || []);
      } catch (error) {
        console.error("Error fetching completed task:", error);
        setError("Failed to load task details.");
      } finally {
        setLoading(false);
      }
    };

    if (taskId) {
      getTaskData();
    }
  }, [taskId]);

  const renderField = (field) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
      case "date":
      case "time":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{field.fieldName}</label>
            <input
              type={field.fieldType}
              defaultValue={field.value}
              key={field.uniqueKey}
              className="border border-gray-300 p-2 my-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        );
      case "textarea":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{field.fieldName}</label>
            <textarea
              defaultValue={field.value}
              key={field.uniqueKey}
              className="border border-gray-300 p-2 my-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
        );
      case "radio":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{field.fieldName}</label>
            {field.radioInputValues.map((option) => (
              <div key={`${field.uniqueKey}-${option}`} className="flex items-center">
                <input
                  type="radio"
                  id={`${field.uniqueKey}-${option}`}
                  name={field.uniqueKey}
                  value={option}
                  defaultChecked={field.value === option}
                  className="mr-2"
                />
                <label htmlFor={`${field.uniqueKey}-${option}`} className="text-sm">{option}</label>
              </div>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div key={field.uniqueKey} className="mb-4 flex items-center">
            <input
              type="checkbox"
              defaultChecked={field.value === "true" || field.value === true}
              key={field.uniqueKey}
              className="mr-2"
            />
            <label className="text-sm">{field.fieldName}</label>
          </div>
        );
      case "file":
      case "scan":
        return (
          <div key={field.uniqueKey} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{field.fieldName}</label>
            {field.value ? (
              <div>
                <a href={field.value} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                  View File
                </a>
              </div>
            ) : (
              <input type="file" key={field.uniqueKey} className="border p-2 w-full rounded-md" />
            )}
          </div>
        );
      default:
        return (
          <div key={field.uniqueKey}>
            <p>Unsupported field type: {field.fieldType}</p>
          </div>
        );
    }
  };

  if (loading) {
    return <div className="text-center p-4">Loading task details...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-lg  p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Update Task</h2>
      <form className="grid grid-col-2">
        {taskDetail.map((field) => renderField(field))}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}
