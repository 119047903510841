import React, { useContext, useEffect, useState } from "react";
import { fetchOrganizations } from "../../../service/Report";
import AppContext from "../../../context/AppContext";
import translations from "../Translations";

const OrganizationFilterSelect = ({
  setSelectedOrgId,
  setSelectedOrgName,
  selectedOrgName,
  setWorkflowName,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const { theme, setWorkflowId , langMode} = useContext(AppContext);
  useEffect(() => {
    const loadOrganizations = async () => {
      try {
        const orgs = await fetchOrganizations();
        setOrganizations(orgs);
        const savedOrgId = localStorage.getItem("selectedOrgId");
        const savedOrgName = localStorage.getItem("selectedOrgName");

        if (savedOrgId && savedOrgName) {
          setSelectedOrgId(Number(savedOrgId));
          setSelectedOrgName(savedOrgName);
        } else {
          const defaultOrg = orgs.find((org) => org.organizationId === 1);
          if (defaultOrg) {
            setSelectedOrgId(defaultOrg.organizationId);
            setSelectedOrgName(defaultOrg.organizationName);
          }
        }
      } catch (error) {
        console.error("Error loading organizations:", error);
      }
    };
    loadOrganizations();
  }, [setSelectedOrgId, setSelectedOrgName]);


  const handleOrganizationChange = (selectedName) => {
    const selectedOrg = organizations.find(
      (org) => org.organizationName === selectedName
    );
    if (selectedOrg) {
      setSelectedOrgId(selectedOrg.organizationId);
      setSelectedOrgName(selectedOrg.organizationName);
      setWorkflowId("");
      setWorkflowName("");
      localStorage.setItem("selectedOrgId", selectedOrg.organizationId);
      localStorage.setItem("selectedOrgName", selectedOrg.organizationName);
    }
  };
  return (
    <>
      <select
        value={selectedOrgName}
        onChange={(e) => handleOrganizationChange(e.target.value)}
        // className={`select cursor-pointer w-56 mr-2 max-w-xs block px-2.5 text-md text-center text-black bg-transparent rounded-xl appearance-none 
        // ${theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : ""}`}
        className={`org cursor-pointer w-56 mr-2 max-w-xs block px-2.5 text-md text-center text-black bg-transparent rounded-xl appearance-none text-sm bg-white  
          ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`} 
      >
        <option value="" disabled>
          Select Organization
        </option>

        {organizations.map((org) => (
          <option
            key={org.organizationId}
            value={org.organizationName}
            className="hover:bg-indigo-700 cursor-pointer"
          >
            {org.organizationName}
          </option>
        ))}
      </select>
    </>
  );
};

export default OrganizationFilterSelect;
